section.search-page {
  .search-result-list {
      width: 100%;
    .search-result-item {
      background-color: $color-light-gray;
      border: 1px solid $color-gray;
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      transition: box-shadow 0.3s;
      width: 100%;
      h5 {
        color: $color-primary-light;
        margin-bottom: 0;
      }
      p {
        color: $color-text-standard;
        margin-top: .5rem;
      }
      &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        text-decoration: none;
      }
    }

    .no-results {
        font-weight: bold;
    }
  }
}
