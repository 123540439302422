@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,600,700,800,900&display=swap");
@import "colors";

body {
  font-family: "Maven Pro", sans-serif;
  p,
  span,
  a {
    font-family: "Maven Pro", sans-serif;
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-break: break-word;
    span {
      font-size: unset;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 2;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.7rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }

  &.medium {
    p,
    span,
    a {
      font-size: 1.1rem;
    }
    h1 {
      font-size: 2.7rem;
    }
    h2 {
      font-size: 2.2rem;
    }
    h3 {
      font-size: 1.9rem;
    }
    h4 {
      font-size: 1.6rem;
    }
    h5 {
      font-size: 1.4rem;
    }
  }
  &.large {
    p,
    span,
    a {
      font-size: 1.2rem;
    }
    h1 {
      font-size: 2.9rem;
    }
    h2 {
      font-size: 2.4rem;
    }
    h3 {
      font-size: 2.1rem;
    }
    h4 {
      font-size: 1.8rem;
    }
    h5 {
      font-size: 1.6rem;
    }
  }
}
