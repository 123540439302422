section.downloads {
  &-dark {
    background-color: $color-light-gray;
    .content {
      .downloads-list {
        .download-item {
          background-color: white !important;
        }
      }
    }
  }
  &-blue {
    background-image: url("../../images/bg_blue.jpg");
    .content {
      color: white !important;
      header {
        h4 {
          color: white !important;
        }
      }
    }
  }
  &-green {
    background-image: url("../../images/bg_green.jpg");
    .content {
      color: white !important;
      header {
        h4 {
          color: white !important;
        }
      }
    }
  }
  .content {
    header {
      h4 {
        color: $color-secondary;
      }
    }
    .downloads-list {
      .download-item {
        height: 100%;
        display: flex;
        background-color: $color-light-gray;
        border-radius: 12px;
        border: 1px solid $color-gray;
        color: $color-text-standard;
        padding: 20px;
        .image-wrapper {
          height: 100px;
          width: 100px;
          margin-right: 20px;
          display: flex;
          img {
            height: 100px;
            width: 100px;
            object-fit: cover;
            border-radius: 12px;
          }
          i {
            font-size: 100px;
            padding-top: 5px;
            margin: 0 auto;
            --fa-secondary-opacity: 1;
            --fa-primary-color: white;
            --fa-secondary-color: #{$color-secondary};
            // --fa-primary-color: rgb(4, 56, 161);
            // --fa-secondary-color: rgb(108, 108, 108);
          }
        }
        .content-inner {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          p {
            margin: auto 0;
            &.item-header {
              color: $color-secondary;
              font-weight: 600;
              margin: 0;
              margin-bottom: auto;
            }
          }
          a {
            margin-top: auto;
            width: fit-content;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
