section.overview-blocks {
  padding: 0;
  padding-bottom: 50px;
  .overview-block {
    margin-top: 50px;
    .overview-block-inner {
      background-size: cover;
      background-color: white;
      background-position: center;
      border-radius: 12px;
      height: 200px;
      display: block;
      position: relative;
      .content {
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(2, 0, 33, 1) 0%, rgba(0, 0, 0, 0) 45%);

        &.text-color-blue {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 0, 0) 45%
          );
          h3 {
            color: $color-secondary;
          }
        }
        &.text-color-green {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 0, 0) 45%
          );
          h3 {
            color: $color-primary;
          }
        }
        h3 {
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: white;
          font-weight: 800;
          letter-spacing: 2;
          max-width: calc(100% - 100px);
        }
        span {
          position: absolute;
          bottom: -15px;
          right: -15px;
          border-radius: 50%;
          background-color: $color-three;
          padding: 35px 37px;
          color: white;
          transition: padding 0.3s;
          i {
            font-size: 25px;
          }
        }
      }
      &:hover {
        .content {
          span {
            padding: 40px 42px;
          }
        }
      }
    }
  }

  &-blue,
  &-green {
    background-size: cover;
    .overview-block {
      .overview-block-inner {
        .content {
          span {
            background-color: white;
            color: $color-three;
          }
        }
      }
    }
  }

  &-dark {
    background-color: $color-gray;
  }
  &-blue {
    background-image: url("../../images/bg_blue.jpg");
  }
  &-green {
    background-image: url("../../images/bg_green.jpg");
  }
}
