section.overview-locations {
  .content {
    header {
      h4 {
        font-weight: 900;
        color: $color-primary;
      }
    }

    .location-list {
      margin-top: 50px;
      > div {
        margin-bottom: 30px;

        .location-item {
          height: 100%;
          border-radius: 12px;
          padding: 20px;
          background-color: $color-light-gray;
          border: 1px solid $color-gray;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          > .title {
            color: $color-primary-light;
            font-weight: 600;
            margin: 20px 0;
          }
          img {
            width: 100%;
            border-radius: 12px;
          }

          .item-content {
            .info-item {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              span {
                width: 20%;
                @include media-breakpoint-down(sm) {
                  width: 100%;
                }
                display: flex;
                font-weight: 500;
                color: $color-text-light;
              }
              p {
                width: 73%;
                @include media-breakpoint-down(sm) {
                  width: 100%;
                }
                margin-left: auto;
                display: flex;
                color: $color-text-light;
              }
            }
          }
          a {
            color: $color-three;
            margin-top: auto;
            display: flex;
            width: fit-content;
          }
        }
      }
    }
  }
}
