section.overview-latest-news {
  &-dark {
    background-color: $color-light-gray;
  }
  .content {
    color: white;
    header {
      h3 {
        font-weight: 900;
        color: $color-primary;
      }
      h4 {
        font-weight: 900;
        color: $color-secondary;
      }
    }
    .content-text {
      margin-bottom: 30px;
      color: $color-text-standard;
      a {
        color: $color-text-standard;
        &:after {
          color: $color-text-standard;
        }
      }
    }
    .latest-news-list {
      .latest-news-item {
        display: flex;
        flex-direction: row;
        height: 180px;
        margin-bottom: 20px;
        padding: 15px;
        background-color: $color-light-gray;
        border: 1px solid $color-gray;
        border-radius: 12px;

        .image-wrapper {
          width: 25%;
          margin-right: 20px;
          flex: 0 0 25%;
          position: relative;
          overflow: hidden;
          border-radius: 6px;
          img {
            border-radius: 6px;
            height: 100%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
          }
        }
        .latest-news-item-content {
          flex-grow: 1;
          overflow: hidden;

          p {
            display: inline-block;
            width: 100%;
            margin-bottom: 0;
            color: $color-text-standard;
            &.latest-news-item-header {
              display: inline-block;
              font-weight: 700;
              color: $color-primary;
            }
            &.date-text {
              margin-bottom: 20px;
              font-weight: 700;
              color: $color-text-light;
            }
          }
        }
      }
    }
  }
}
