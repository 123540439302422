form {
    .wpcf7-form-control-wrap {
        max-width: 100%;
        * {
            max-width: 100%;
        }
    }

    .wpcf7-form-control-wrap {
        input,
        select,
        textarea {
            &:not([type="checkbox"]) {
                border: 1px solid #c8c8c8;
                background-color: white;
                padding: 10px;
                width: 100%;
            }
        }
    }
    label {
        display: flex;
        flex-direction: row;
        span {
            margin-left: 0;
            input[type="checkbox"] {
                appearance: none;

                &:before {
                    content: "";
                    position: relative;
                    border: 1px solid #c8c8c8;
                    background-color: #ffffff;
                    height: 15px;
                    width: 15px;
                    display: flex;
                    margin-top: 5px;
                    margin-right: 10px;
                    padding: 0 2px;
                    font-size: 10px;
                    height: 16px;
                    width: 16px;
                }
                &:checked {
                    &:before {
                        content: "\f00c";
                        font-family: "Font Awesome 5 Pro";
                        height: unset;
                        width: unset;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
    span.wpcf7-list-item {
        margin: 0;
    }
}
