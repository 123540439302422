section.usp {
  &-dark {
    background-color: $color-light-gray;
  }

  .content {
    text-align: center;
    header {
      h4 {
        color: $color-secondary-dark;
        font-weight: 900;
      }
      margin-bottom: 40px;
    }
    .content-text {
      @include media-breakpoint-up(xl) {
        padding: 0 200px;
      }
    }
    .usp-list {
      margin-top: 30px;
      .usp-item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .icon-wrapper {
          margin: auto;
          background-color: $color-secondary-dark;
          border-radius: 50%;
          font-size: 3em;
          border-radius: 50%;
          border: solid white;
          color: white;
          line-height: 2em;
          width: 2em;
          height: 2em;
          text-align: center;
          display: inline-block;
          padding: 15px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        p {
          margin-bottom: 0;
          &.usp-item-header {
            margin-top: 10px;
            color: $color-primary;
            font-weight: 500;
          }
        }
      }
    }
  }
}
