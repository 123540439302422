.featured-button {
    position: fixed;
    z-index: 300;
    background-color: #ff0000;
    display: flex;
    bottom: 0;
    right: 0;
    box-shadow: 0px 0px 20px rgba(0,0,0,.19), 0 2px 6px rgba(0,0,0,.23);
    width: 100%;
    .content {
        text-align: center;
        width: 100%;
        h5,
        h4,
        span {
            color: white;
            text-decoration: none !important;
            text-transform: uppercase;
        }
    }

    &:hover {
        text-decoration: none;
        .content {
            h5,
            h4,
            span {
                color: white;
                text-decoration: none !important;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .featured-button {
        position: fixed;
        z-index: 300;
        border-radius: 50%;
        height: 200px;
        width: 200px;
        text-align: center;
        display: flex;
        bottom: 50px;
        right: 50px;
        justify-content: center;
        transition: box-shadow 0.3s, transform 0.3s;
        border: white 4px solid;
        .content {
            height: min-content;
            margin: auto;
            h4,
            h5,
            span {
                color: white;
                text-decoration: none !important;
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        &:hover {
            text-decoration: none;
            box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 4px 10px rgba(0,0,0,0.22);
            transform: scale(1.2);
            .content {
                h5,
                h4,
                span {
                    color: white;
                    text-decoration: none !important;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .featured-button {
        height: 100px;
        padding: 10px 0;
        .content {
            h3 {
                font-size: 1.1rem;
            }
            h4 {
                font-size: 1rem;
            }
            span {
                font-size: 0.8rem;
            }
        }
    }
}
