section.main-content {
  background-color: white;
  & + section.main-content {
    &:not(.main-content-dark) {
      &:not(.main-content-blue) {
        &:not(.main-content-green) {
          &:not(.main-content-white) {
            padding-top: 0;
          }
        }
      }
    }
  }

  &-white {
    background-color: white;
    & + section.main-content-white {
      padding-top: 0;
    }
  }

  &-dark {
    background-color: $color-light-gray;
    & + section.main-content-dark {
      padding-top: 0;
    }
  }
  &-blue {
    background-color: $color-secondary;
    color: white !important;
    h2,
    h3,
    a {
      color: white !important;
    }
    & + section.main-content-blue {
      padding-top: 0;
    }
  }
  &-green {
    background-color: $color-primary;
    color: white !important;
    h2,
    h3,
    a {
      color: white !important;
    }
    & + section.main-content-green {
      padding-top: 0;
    }
  }

  .main-content-row {
    &-left {
      flex-direction: row-reverse;
    }
    &-center {
      text-align: center;
    }
    .content {
      margin-bottom: 30px;
      h2 {
        color: $color-primary;
        font-weight: 600;
      }
      h3 {
        color: $color-secondary;
        font-weight: 600;
      }
      .inner-content {
        h3 {
          margin: 20px 0;
          color: $color-secondary;
        }

        &.location-item-content {
          .info-item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 0;
            &.full-width {
              span,
              p {
                width: 100%;
                margin-bottom: 20px;
              }
            }
            span {
              width: 20%;
              display: flex;
              font-weight: 500;
              color: $color-text-light;
            }
            p {
              width: 73%;
              margin-left: auto;
              margin-bottom: 0;

              display: flex;
              color: $color-text-light;
            }
          }
          a {
            color: $color-three;
            margin-top: auto;
            display: flex;
            width: fit-content;
          }
        }
      }
      .link-wrapper {
        margin-top: 30px;
        .link-arrow {
          color: $color-three;
          font-weight: 400;
          &:after {
            color: inherit;
          }
        }
      }
    }

    .media-wrapper {
      margin-bottom: 30px;
      margin-top: 0;
      iframe {
        border: 1px solid $color-border;
        max-width: 100%;
      }
      img {
        max-width: 100%;
      }
    }

    .side-menu {
      background-image: url("../../images/bg_blue.jpg");
      min-height: 100%;
      padding: 40px;
      color: white;
      header {
        margin-bottom: 30px;
        h3 {
          font-weight: 800;
        }
      }
      .navbar-side {
        list-style: none;
        padding-left: 0;
        li {
          position: relative;
          margin-bottom: 20px;
          &.menu-item-has-children {
            &:after {
              content: "\f078";
              color: white;
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              top: 5px;
              right: 0;
              z-index: 1;
            }
            &.show {
              &:after {
                content: "\f077";
              }
            }
          }

          > a {
            color: white;
            text-decoration: none;
            font-size: 1.2rem;
            font-weight: 500;
          }
          .dropdown-menu {
            &.show {
            }
            float: none;
            position: relative !important;
            transform: none !important;
            width: 100%;
            background-color: transparent;
            border: none;
            padding-left: 20px;
            a {
              display: block;
              color: white;
              font-size: 1.2rem;
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  &-columns {
    .container {
      .main-content-row {
        .content {
          img {
            max-width: 100%;
          }
          header {
            height: 5rem;
            overflow: hidden;
          }
          &-contact {
            header {
              margin: 0;
              height: unset;
              > * {
                margin: 0;
                padding: 20px 30px;
                font-size: 1.5rem;
              }

              &.bg-color {
                &-blue {
                  background-color: $color-secondary;
                }
                &-green {
                  background-color: $color-primary;
                }
                &-white {
                  background-color: #fff;
                }
              }
              &.text-color {
                &-white {
                  > * {
                    color: white;
                  }
                }
                &-black {
                  > * {
                    color: $color-text-standard;
                  }
                }
                &-blue {
                  > * {
                    color: $color-secondary;
                  }
                }
                &-green {
                  > * {
                    color: $color-primary;
                  }
                }
              }
            }
            .inner-content {
              border: 1px solid $color-border;
              border-top: none;
              padding: 30px;
              &-border-color {
                &-blue {
                  border-color: $color-secondary;
                }
                &-green {
                  border-color: $color-primary;
                }
                &-white {
                  border-color: #fff;
                }
              }
              &-text-color {
                &-white {
                  p {
                    color: white;
                  }
                }
                &-black {
                  p {
                    color: $color-text-standard;
                  }
                }
                &-blue {
                  p {
                    color: $color-secondary;
                  }
                }
                &-green {
                  p {
                    color: $color-primary;
                  }
                }
              }

              &-icon-color {
                &-orange {
                  i {
                    color: $color-three;
                  }
                }
                &-white {
                  i {
                    color: white;
                  }
                }
                &-black {
                  i {
                    color: $color-text-standard;
                  }
                }
                &-blue {
                  i {
                    color: $color-secondary;
                  }
                }
                &-green {
                  i {
                    color: $color-primary;
                  }
                }
              }
              .contact-row {
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
                flex-wrap: wrap;
                i {
                  padding-right: 40px;
                  width: 18px;
                  line-height: 1.7rem;
                }
                p {
                  font-weight: 600;
                  margin-bottom: 0;
                  width: calc(100% - 50px);
                  a {
                    color: $color-text-standard;
                  }
                }
                @include media-breakpoint-only(lg) {
                  flex-direction: column;
                  justify-content: center;
                  i {
                    text-align: center;
                    padding-right: 0;
                    margin: auto;
                  }
                  p {
                    width: 100%;
                    text-align: center;
                    margin: auto;
                  }
                }

                @include media-breakpoint-down(xs) {
                  flex-direction: column;
                  justify-content: center;
                  i {
                    text-align: center;
                    padding-right: 0;
                    margin: auto;
                  }
                  p {
                    width: 100%;
                    text-align: center;
                    margin: auto;
                  }
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
            &-under_title {
              margin-top: calc(5rem + 25px);
            }
          }
          &-left {
            text-align: left !important;
          }

          &-right {
            text-align: right !important;
          }

          &-center {
            text-align: center !important;
          }

          &-spread {
            text-align: justify !important;
          }
        }
        > div {
          .content-contact {
          }
          &:first-of-type {
            .content-contact {
              padding-left: 0;
            }
          }

          &:last-of-type {
            .content-contact {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
