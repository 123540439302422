nav.navbar {
  border-top: 3px solid $color-primary;
  padding: 15px;
  background-color: white;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  min-height: 100px;
  @include media-breakpoint-up(lg) {
    height: 100px;
  }
  .container {
    height: 100%;
    .navbar-brand {
      height: 100%;
      padding-right: 40px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 60%;
      img {
        max-height: 100%;
        max-width: 100%;
        padding-bottom: 4px;
      }
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        width: 200px;
        display: flex;
        img {
          margin: auto 0;
        }
      }
      @include media-breakpoint-only (lg) {
        position: absolute;
        top: 10px;
        width: 140px;
      }
    }
    .navbar-toggler {
      border: none;
      &:focus {
        outline: none;
      }
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0,100,184)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
    .collapse,
    .collapsing {
      display: flex;
      flex-direction: column;
      height: 100%;
      @include media-breakpoint-down(md) {
        height: 0;
        overflow: hidden;
        padding: 0 20px;
        &.show {
          height: 100%;
        }
      }
      .toolbar {
        display: flex;
        align-self: flex-end;

        @include media-breakpoint-down(md) {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 35px 0;
        }
        form.searchform {
          div {
            position: relative;
            input {
              position: absolute;
              top: -9px;
              width: 0;
              right: -39px;
              transition: width 0.3s;
              overflow: hidden;
              border: none;
              visibility: hidden;
              padding: 0;
            }
            button {
              position: absolute;
              top: -1px;
              width: 100%;
              right: -21px;
              background: none;
              border: none;
              visibility: hidden;
            }
          }
          &.active {
            div {
              input {
                visibility: visible;
                width: 200px;
                border: 1px solid $color-secondary-dark;
                border-radius: 8px;
                padding: 3px;
              }
              button {
                visibility: visible;
                i {
                  color: $color-secondary-dark;
                }
              }
            }
          }
        }
        a {
          margin-left: 15px;
          cursor: pointer;
          i {
            color: $color-secondary-dark;
          }
          @include media-breakpoint-down(md) {
            font-size: 1.4rem;
            margin-left: 0;
          }
        }
      }
      .navbar-nav {
        display: flex;
        align-self: flex-end;
        width: 100%;
        justify-content: space-between;
        margin-top: auto;
        li {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          @include media-breakpoint-down(md) {
            line-height: 2.5rem;
            flex-direction: row;
          }
          &.menu-item-has-children {
            // &:before {
            //   content: "\f078";
            //   font-family: "Font Awesome 5 Pro";
            //   position: absolute;
            //   color: $color-secondary-dark;
            //   top: 50%;
            //   transform: translateY(-45%);
            //   right: 8px;
            //   @include media-breakpoint-down(md) {
            //     position: relative;
            //     margin-right: 10px;
            //     right: unset;
            //     top: 0;
            //     transform: none;
            //   }
            // }
            // &.show {
            //   &:before {
            //     content: "\f077";
            //   }
            // }
            a {
              // padding-right: 30px;
              z-index: 1;
            }
          }
          a {
            margin: auto;
            display: flex;
            color: $color-secondary-dark;
            font-weight: 500;
            padding: 0;
            font-size: .9rem;
            @include media-breakpoint-down(md) {
              flex-grow: 1;
            }
            &:hover {
              color: $color-secondary;
              text-decoration: none;
            }
            &:after {
              display: flex;
              margin-top: auto;
              height: 0.8rem;
            }
          }
          .dropdown-menu {
            border: none;
            padding-left: 25px;
            width: 100%;
            margin-top: 0;
            padding-top: 0;
            @include media-breakpoint-up(lg) {
              background-color: white;
              border: 1px solid $color-gray;
              border-left: 2px solid $color-primary;
              padding: 0;
              border-radius: 0;
              margin-top: unset;
              width: max-content;
              > a {
                padding: 15px;
                position: relative;
                color: $color-text-standard;
                &:not(:last-of-type) {
                  &:after {
                    content: "";
                    height: 1px;
                    width: calc(100% - 30px);
                    background-color: $color-gray-dark;
                    position: absolute;
                    bottom: 0;
                    left: 15px;
                  }
                }
              }
            }
          }
          @include media-breakpoint-up(lg) {
            &.button {
              > a {
                border-radius: 6px;
                background-color: $color-three;
                color: white;
                padding: 6px 30px;
                &:hover {
                  color: white;
                  background-color: darken($color-three, 5);
                }
              }
              &.menu-item-has-children {
                &::before {
                  right: 20px;
                  color: white;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
    }
  }
}
