$color-primary: #419644;
$color-primary-light: #3c9b32;
$color-secondary: #0064b8;
$color-secondary-dark: #0065a2;
$color-three: #e2760c;
$color-text-standard: #3d3d3d;
$color-text-light: #6c6c6c;
$color-gray: #e3e3e3;
$color-light-gray: #f7f7f7;
$color-gray-dark: #e5e5e5;
$color-border: #dfdfdf;