@import "../../../node_modules/bootstrap/scss/bootstrap";

@import "assets/fonts";
@import "assets/colors";
@import "assets/buttons";
@import "assets/forms";
@import "parts/nav";
@import "parts/header";
@import "parts/footer";
@import "parts/main-content";
@import "parts/content-columns";
@import "parts/overview-blocks";
@import "parts/usp-block";
@import "parts/downloads";
@import "parts/featured-button";
@import "parts/overview-block-activities";
@import "parts/latest-news-overview";
@import "page/page-location-overview";
@import "page/page-activity-overview";
@import "page/page-job-offer-overview";
@import "page/page-search";
@import "page/page-contact";
@import "page/page-history";

body {
  margin-top: 100px;
  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

section {
  padding: 50px 0;
  .content {
    header {
      h4 {
        font-weight: 900;
      }
      margin-bottom: 20px;
    }
  }
}


iframe {
  max-width: 100% !important;
}