section.overview-activities {
  &-dark {
    background-color: $color-light-gray;
    .content {
      .activities-list {
        .activity-item {
          padding: 20px;
          background-color: white !important;
        }
      }
    }
  }
  &-blue {
    background-image: url("../../images/bg_blue.jpg");
    .content {
      color: white !important;
      header {
        h4 {
          color: white !important;
        }
      }
    }
  }
  &-green {
    background-image: url("../../images/bg_green.jpg");
    .content {
      color: white !important;
      header {
        h4 {
          color: white !important;
        }
      }
    }
  }
  .content {
    color: $color-text-standard;

    header {
      h4 {
        font-weight: 900;
      }
    }
    .content-text {
      margin-bottom: 30px;
      a.link-arrow {
        color: inherit;
        &:after {
          color: inherit;
        }
      }
    }

    .activities-list {
      .col-lg-4 {
        display: flex;
      }
      .activity-item {
        width: 100%;
        padding: 20px;
        background-color: $color-light-gray;
        border-radius: 12px;
        border: 1px solid $color-gray;
        color: $color-text-standard;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
        }
        .activity-item-header {
          display: inline-block;
          font-weight: 700;
          color: $color-primary;
          margin-bottom: 50px;
        }
        p {
          margin-bottom: 0;
          &.date-text {
            font-weight: 700;
            color: $color-text-light;
            margin-top: 20px;
            margin-bottom: 0;
          }
          &:first-of-type() {
            margin-top: auto;
          }
        }
      }
    }
  }
}
