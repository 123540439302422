a.link-arrow {
  position: relative;
  color: white;
  font-weight: 600;
  &:hover {
    &:after {
      right: -25px;
    }
  }

  &.light {
    font-weight: 400;
  }
  &-dark {
    color: $color-text-standard;
  }

  &:after {
    transition: all 0.3s;
    content: "\f061";
    color: inherit;
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 50%;
    transform: translateY(-43%);
    right: -20px;
  }
}

.btn {
  border-radius: 6px;
  background-color: $color-three;
  color: white;
  padding-right: 30px;
  padding-left: 30px;
  &:hover {
    color: white;
    background-color: darken($color-three, 5);
  }
}
