.history-row {
  padding-bottom: 5px;
  background-image: url("../../images/bg_blue.jpg");
  padding-top: 300px;
}

.milestonebox {
  padding-bottom: 30px;
  position: relative;
  padding-top: 55px;
}

.milestonecontent {
  border: 1px solid #e9e9e9;
  background-color: #f5f3f3;
  position: relative;
  z-index: 10;
  padding: 0;
  .milestone-image {
    padding: 0;
    img {
      width: 100%;
    }
  }
  .milestone-desc {
    padding: 25px;
    text-align: left;
    h4 {
      font-size: 1.5rem;
      font-weight: 800;
      letter-spacing: 1px;
      color: $color-secondary;
    }
  }
}

.milestonebox:first-child h4,
.milestonebox:last-child h4 {
  font-size: 30px;
}

.milestonebox:before {
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 0;
  width: 2px;
  background: white;
  height: 100%;
  z-index: 0;
}

.milestone {
  position: relative;
}
.milestone:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 24px;
  height: 2px;
  background: white;
  width: 85px;
  z-index: 1;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a002252', endColorstr='#002252',GradientType=1 ); /* IE6-9 */
}
.milestone.rightsided:before {
  content: "";
  position: absolute;
  right: 0;
  left: 10px;
  top: 24px;
  height: 2px;
  background: white;
  width: 85px;
  z-index: 1;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 72%,
    rgba(255, 255, 255, 0.1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 72%,
    rgba(255, 255, 255, 0.1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 72%,
    rgba(255, 255, 255, 0.1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002252', endColorstr='#1a002252',GradientType=1 ); /* IE6-9 */
}
.milestonebox:first-child .milestone:before,
.milestonebox:last-child .milestone:before {
  display: none;
}

.jaartal {
  font-size: 25px;
  font-weight: 600;
  color: white;
  padding: 1px 10px 5px;
  border: 3px solid #fff;
  text-align: center;
  width: 110px;
  position: absolute;
  line-height: 1;
  top: -170px;
  left: calc(50% - 55px);
  background-color: $color-secondary;
}
.jaartal:before {
  content: "";
  position: absolute;
  left: calc(50% - 13px);
  bottom: -60px;
  background: $color-secondary;
  width: 26px;
  border: 3px solid #fff;
  height: 26px;
  z-index: 1;
}

.milestonebox:last-child {
  padding-bottom: 0;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .milestone {
    padding-right: 120px;
    padding-left: 10px;
    margin-top: -170px;
  }
  .milestone.rightsided {
    padding-left: 120px;
    padding-right: 10px;
  }

  .milestonebox:last-child {
    padding-top: 40px;
  }

  .milestonebox:first-child .milestone,
  .milestonebox:last-child .milestone {
    margin-left: 25%;
    left: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
  .milestonebox:last-child .jaartal {
    top: -80px;
  }

  .milestonebox:first-child .milestone {
    margin-bottom: 170px;
  }
  .milestonebox:last-child .milestone {
    margin-top: 0;
  }

  .milestonebox:first-child .jaartal {
    top: -235px;
    font-size: 40px;
    width: 170px;
    background: none;
    color: #fff;
    left: calc(50% - 85px);
  }
  .milestonebox:first-child .jaartal:before {
    content: "";
    background: none;
    border: 3px solid #fff;
    bottom: -50px;
  }
  .milestonebox:first-child .jaartal:after {
    content: "";
    background: #fff;
    width: 2px;
    height: 25px;
    position: absolute;
    bottom: -25px;
    left: calc(50% - 1px);
  }
  .milestonebox:first-child .milestone:after {
    content: "";
    background: #fff;
    width: 2px;
    height: 25px;
    position: absolute;
    top: -22px;
    left: calc(50% - 1px);
  }
}

@media screen and (max-width: 767px) {
  .pagina-banner {
    padding: 130px 0;
    min-height: 400px;
  }
  .jaartal {
    top: 0;
  }
}

.milestone {
  opacity: 0;
  -ms-transform: translate(0, 100px);
  -webkit-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.jaartal {
  opacity: 0;
  -ms-transform: translate(0, -100px);
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -100px);
}

.animated .milestone,
.animated .jaartal {
  opacity: 1;
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.milestone,
.jaartal {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
