section.overview-activities {
  .content {
    header {
      h4 {
        font-weight: 900;
        color: $color-primary;
      }
    }

    .activity-list {
      margin-top: 50px;
      > div {
        margin-bottom: 30px;
        .activity-filter-wrapper {
          background-image: url("../../images/bg_green.jpg");
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px 50px;
          border-radius: 12px;
          > * {
            flex: 0 0 30%;
            margin: auto 0;
            @include media-breakpoint-down(md) {
              flex: 0 0 100%;
              margin: 10px 0;
            }
          }
          .input-wrapper {
            position: relative;
            margin: 0;
            height: auto;
            input {
              border-radius: 6px;
              border: none;
              padding: 10px;
              width: 100%;
              height: 100%;
              &:focus {
                outline: none;
              }
            }
            i {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              color: $color-primary;
            }
          }
          p {
            color: white;
            text-align: center;
          }
          .button-wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            .filter-box {
              position: absolute;
              width: 100%;
              background-color: white;
              border-radius: 6px;
              top: calc(100% + 15px);
              left: 0;
              z-index: 1;
              visibility: hidden;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
              &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 100%;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom-color: #fff;
              }
              &-calendar {
                &:after {
                  left: 12%;
                }
              }
              &-location {
                &:after {
                  left: 47%;
                }
              }
              &-type {
                &:after {
                  left: 82%;
                }
              }
              @include media-breakpoint-down(xs) {
                &-calendar {
                  top: calc(33% - 1px);
                }
                &-location {
                  top: calc(66% - 1px);
                }
                &-type {
                  top: calc(99% - 1px);
                }
              }

              ul {
                list-style: none;
                padding: 15px;
                margin: 0;
                li {
                  margin-bottom: 5px;
                  display: flex;
                  flex-direction: row;
                  align-items: baseline;
                  input {
                    margin-right: 10px;
                  }
                  a {
                    color: $color-secondary;
                  }
                }
              }
            }
            .filter-button {
              border-radius: 6px;
              background-color: white;
              padding: 5px 20px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              flex-grow: 1;
              flex-basis: 0;
              flex: 0 0 30%;
              @include media-breakpoint-only(lg) {
                padding: 5px 15px;
              }
              @include media-breakpoint-down(xs) {
                flex: 0 0 100%;
                margin-bottom: 10px;
              }
              i {
                color: $color-primary;
                margin: 0 auto;
                font-size: 1.3rem;
                line-height: 1.3;
              }
              p {
                margin: 0 auto;
                color: $color-primary;
              }
              &.active {
                border: 2px solid lighten($color-primary-light, 20);
                padding: 3px 18px;
                @include media-breakpoint-only(lg) {
                  padding: 3px 13px;
                }
              }
              &:hover {
                cursor: pointer;
              }
            }

            .filter-button.active + .filter-box {
              visibility: visible;
            }
          }
        }
        .activity-item {
          height: 100%;
          border-radius: 12px;
          padding: 20px;
          background-color: $color-light-gray;
          border: 1px solid $color-gray;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          transition: box-shadow 0.3s;
          &:hover {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            text-decoration: none;
          }
          .date {
            margin-bottom: 5px;
            margin-top: 20px;
            color: $color-text-standard;
            font-weight: 600;
            &::first-letter {
              text-transform: capitalize;
            }
          }
          > .title {
            color: $color-primary-light;
            font-weight: 600;
            margin-bottom: 20px;
          }
          img {
            width: 100%;
            border-radius: 12px;
          }

          .item-content {
            .info-item {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              span {
                font-weight: 500;
                color: $color-text-light;
              }
              p {
                margin-left: 10px;
                color: $color-text-light;
                margin-bottom: 0;
              }
            }
          }
          a {
            color: $color-three;
            margin-top: auto;
            display: flex;
            width: fit-content;
          }
        }
      }
    }
  }
}
