header.header-slide {
  padding: 50 0;
  background-color: white;
  &-dark {
    background-color: $color-gray;
  }
  &-blue {
    background-image: url("../../images/bg_blue.jpg");
  }
  &-green {
    background-image: url("../../images/bg_green.jpg");
  }
  .carousel-wrapper {
    .carousel {
      .carousel-indicators {
        li {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid white;
          transition: all 0.8s;
          &.active {
            transition: all 0.8s;
            background-color: $color-three;
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .carousel-inner {
        border-radius: 10px;
        .carousel-item {
          .content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -100%;
            width: 30%;
            z-index: 2;
            color: white;
            transition: all 0.8s;
            h2 {
              letter-spacing: 2;
              font-weight: 700;
            }
            a {
              color: white;
              font-weight: 600;
            }
            p {
              color: white;
            }
            @include media-breakpoint-down(md) {
              width: 80%;
              h2 {
                font-size: 1.5rem;
              }
              a,
              p {
                font-size: 0.8rem;
              }
            }
            @include media-breakpoint-down(xs) {
              p {
                display: none;
              }
            }
          }
          .content-border {
            position: absolute;
            top: -10%;
            left: 0;
            width: 0;
            height: 120%;
            z-index: 1;
            border-radius: 87% 13% 25% 75% / 0 55% 45% 0%;
            transition: all 0.8s;
            &-1 {
              background-color: rgba(60, 140, 65, 0.45);
            }
            &-2 {
              background-color: rgba(0, 111, 162, 0.45);
            }
            &-3 {
              background-color: rgba(60, 140, 65, 0.45);
            }
          }
          &.active {
            .content {
              left: 100px;
              @include media-breakpoint-down(md) {
                left: 10px;
              }
            }
            .content-border {
              &-1 {
                width: 40%;
              }
              &-2 {
                border-radius: 87% 13% 13% 87% / 0% 55% 45% 0%;
                width: 42%;
              }
              &-3 {
                border-radius: 87% 13% 6% 94% / 0% 55% 45% 0%;
                width: 44%;
              }
              @include media-breakpoint-down(md) {
                &-1 {
                  width: 85%;
                }
                &-2 {
                  width: 87%;
                }
                &-3 {
                  width: 89%;
                }
              }
            }
          }
        }
      }
      .carousel-control {
        width: 8%;
        color: $color-primary;
        i {
          background-color: white;
          border-radius: 50%;
          padding: 8px 9px;
          font-size: 20px;
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}
