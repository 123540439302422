section.overview-job-offers {
  background-color: $color-light-gray;
  .content {
    color: $color-text-standard;
    header {
      h3 {
        color: $color-primary;
        font-weight: 800;
      }
    }

    > .row {
      margin-top: 50px;
    }

    .location-list {
      .job-offer-item {
        background-color: white;
        border: 1px solid $color-border;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px;
        margin-bottom: 30px;
        justify-content: space-between;
        p,
        a {
          flex: 0 0 22%;
          display: flex;
          flex-wrap: wrap;
          color: $color-text-standard;
          flex-direction: column;
          align-items: center;
          text-align: center;
          position: relative;
          padding: 20px 0;
          margin-bottom: 0;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            text-align: center;
            flex: 0 0 45%;
            justify-content: space-between;
          }

          i {
            line-height: unset;
            color: $color-secondary-dark;
            position: absolute;
            top: 0;
          }
          &.link {
            color: $color-three;
            @include media-breakpoint-down(sm) {
              flex: 0 0 100%;
              margin-top: 20px;
            }
          }
        }
        p.title {
          flex: 0 0 31%;
          font-weight: 600;
          align-items: flex-start;
          text-align: left;
          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            margin-bottom: 20px;
            align-content: center;
          }
        }
        a:hover {
          text-decoration: none;
          color: $color-secondary-dark;
        }
      }
    }
    .sidebar {
      header {
        h4 {
          color: $color-primary;
        }
      }
      .image-wrapper {
        img {
          width: 100%;
        }
      }
    }
  }
}
