footer {
  background-image: url("../../images/bg_blue.jpg");
  .footer-row {
    padding: 50px 0;

    .footer-wrapper {
      padding-right: 50px;
      hr {
        border-color: white;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
    .footer-col {
      color: white;
      position: relative;

      .content {
        p,
        ul {
          margin-top: 30px;
        }
        ul {
          padding-left: 0;
          list-style: none;
          li {
            position: relative;
            a {
              color: white;
              display: block;
            }
            margin-bottom: 15px;
            &:hover {
              color: white;
              &:after {
                right: -25px;
              }
            }

            &:after {
              transition: all 0.3s;
              content: "\f061";
              color: white;
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              top: 50%;
              transform: translateY(-43%);
              right: 0;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    .footer-bottom-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 13px;
      font-weight: 400;
      p {
        font-size: 13px;
        margin-bottom: 0;
      }
      a {
        font-size: 13px;
        text-decoration: underline;
        transition: all .3s;
        &:hover {
          color: $color-secondary !important;
        }
      }
      .links {
        a {
          + a {
            margin-left: 10px;
            border-left: 1px solid;
            padding-left: 10px;

          }
        }
      }
      .links-social {
        a {
          margin-left: 10px;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
}
