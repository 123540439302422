section.contact-page {
  .contact-page-inner {
    .contact-block {
      header {
        h3 {
          color: $color-secondary;
          font-weight: 700;
          margin-bottom: 30px;
        }
      }
      &-form {
        form {
          p {
            span {
              input,
              select,
              textarea {
                &:not([type="checkbox"]) {
                  border: 1px solid #c8c8c8;
                  background-color: white;
                  padding: 10px;
                  width: 100%;
                }
              }
            }
            label {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              span {
                margin-left: 0;
                input[type="checkbox"] {
                  appearance: none;

                  &:before {
                    content: "";
                    position: relative;
                    border: 1px solid #c8c8c8;
                    background-color: #ffffff;
                    height: 15px;
                    width: 15px;
                    display: flex;
                    margin-top: 5px;
                    margin-right: 10px;
                    padding: 0 2px;
                    font-size: 10px;
                    height: 16px;
                    width: 16px;
                  }
                  &:checked {
                    &:before {
                      content: "\f00c";
                      font-family: "Font Awesome 5 Pro";
                      height: unset;
                      width: unset;
                    }
                  }
                  &:focus {
                      outline: none;
                  }
                }
              }
            }
          }
        }
        @include media-breakpoint-up(lg) {
          padding-right: 50px;
        }
      }

      &-info {
        .contact-info-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          color: $color-text-standard;
          margin: 30px 0;
          span {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 20%;
            font-weight: bold;
          }
          p {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 79%;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
